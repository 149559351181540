import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./App.css";
import "antd/dist/antd.css";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";

import externalForm01 from "./Forms/CASA_IDEAS/externalForm01";
import externalForm01K from "./Forms/KETAL_USER/externalForm01";
import externalForm02 from "./Forms/CASA_IDEAS/externalForm02";
import externalForm02K from "./Forms/KETAL_USER/externalForm02";
import externalForm03 from "./Forms/CASA_IDEAS/externalForm03";

import externalForm04 from "./Forms/CASA_IDEAS/externalForm04";
import externalForm04K from "./Forms/KETAL_USER/externalForm04";
import externalManageSKU from "./Forms/CASA_IDEAS/externalManageSKU";
import externalUpdateSKU from "./Forms/CASA_IDEAS/externalUpdateSKU";
import reportSAP01 from "./Forms/CASA_IDEAS/reportSAP01";
import reportSAP02 from "./Forms/CASA_IDEAS/reportSAP02";
import reportArqueo1 from "./Forms/CASA_IDEAS/reportArqueo1";
import reportArqueo2 from "./Forms/CASA_IDEAS/reportArqueo2";
import reportArqueo3 from "./Forms/CASA_IDEAS/reportArqueo3";

import reportBloqueos from "./Forms/CASA_IDEAS/reportBloqueos";
import resetFlowForm from "./Forms/CASA_IDEAS/resetFlowForm.js";

import homepage from "./Forms";
import externalFormOP01 from "./Forms/OPENCART/externalForm01";
import externalFormOP02 from "./Forms/OPENCART/externalForm02";
import externalFormOP03 from "./Forms/OPENCART/externalForm03";
import externalFormOP04 from "./Forms/OPENCART/externalForm04";
import externalFormOP06 from "./Forms/OPENCART/externalForm06";
import externalFormOP07 from "./Forms/OPENCART/externalForm07";
import externalForm08 from "./Forms/CASA_IDEAS/externalForm08";
import externalForm09 from "./Forms/CASA_IDEAS/externalForm09";

import externalForm10 from "./Forms/CASA_IDEAS/externalForm10";

import cform01 from "./Forms/customer_forms/cform01";
import cform02 from "./Forms/reportExportOrders/index";

import cform03 from "./Forms/reportExportOrders/indexEvent";

import cform02W2 from "./Forms/reportExportOrders2/index";
import cform03W2 from "./Forms/reportExportOrders2/indexEvent";
import cform04W2 from "./Forms/reportExportOrders2/indexHist";
import cform05W2 from "./Forms/reportExportOrders2/indexCuadratura";

import externalForm02W2 from "./Forms/CASA_IDEASW2/externalForm02";
import externalForm03W2 from "./Forms/CASA_IDEASW2/externalForm03";
import externalForm04W2 from "./Forms/CASA_IDEASW2/externalForm04";
import externalForm06W2 from "./Forms/CASA_IDEASW2/externalForm06";
import externalForm07W2 from "./Forms/CASA_IDEASW2/externalForm07";
import externalForm08W2 from "./Forms/CASA_IDEASW2/externalForm08";
import externalForm10W2 from "./Forms/CASA_IDEASW2/externalForm10";
import reportArqueo2W2 from "./Forms/CASA_IDEASW2/reportArqueo2";
import reportArqueo3W2 from "./Forms/CASA_IDEASW2/reportArqueo3";

import externalForm02W3 from "./Forms/CASA_IDEASW3/externalForm02";
import externalForm03W3 from "./Forms/CASA_IDEASW3/externalForm03";
import externalForm04W3 from "./Forms/CASA_IDEASW3/externalForm04";
import externalForm06W3 from "./Forms/CASA_IDEASW3/externalForm06";
import externalForm07W3 from "./Forms/CASA_IDEASW3/externalForm07";
import externalForm08W3 from "./Forms/CASA_IDEASW3/externalForm08";
import externalForm10W3 from "./Forms/CASA_IDEASW3/externalForm10";
import reportArqueo2W3 from "./Forms/CASA_IDEASW3/reportArqueo2";
import reportArqueo3W3 from "./Forms/CASA_IDEASW3/reportArqueo3";
import reportArqueo4W3 from "./Forms/CASA_IDEASW3/reportArqueo4";

const routing = (
  <Router>
    <div>
      <Route path="/index" exact component={homepage} />
      <Route path="/" exact component={homepage} />
      {/****formulario genericos****/}
      <Route
        exact
        path="/OPENCART/externalForm01/:WO_Id/:WOTaskId/:org"
        component={externalFormOP01}
      />
      <Route
        path="/OPENCART/externalForm02/:WO_Id/:WOTaskId/:org"
        component={externalFormOP02}
      />
      <Route
        path="/OPENCART/externalForm03/:WO_Id/:WOTaskId/:org"
        component={externalFormOP03}
      />
      <Route
        exact
        path="/OPENCART/externalForm04/:WO_Id/:WOTaskId/:org"
        component={externalFormOP04}
      />
      <Route
        path="/OPENCART/externalForm05/:CUSTOM_PARAM/:WO_Id/:WOTaskId/:org"
        component={externalFormOP04}
      />
      <Route
        path="/OPENCART/externalForm06/:WO_Id/:WOTaskId/:token/:org"
        component={externalFormOP06}
      />

      <Route
        exact
        path="/OPENCART/externalForm01/:WO_Id/:WOTaskId/:token/:org"
        component={externalFormOP01}
      />
      <Route
        exact
        path="/OPENCART/externalForm04/:WO_Id/:WOTaskId/:token/:org"
        component={externalFormOP04}
      />

      <Route
        exact
        path="/OPENCART/externalForm07/:WO_Id/:WOTaskId/:token/:org"
        component={externalFormOP07}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm08/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm08}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm09/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm09}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm10/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm10}
      />

      {/****formulario CI****/}
      <Route
        exact
        path="/CASA_IDEAS/externalForm01/:WO_Id/:WOTaskId"
        component={externalForm01}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm03/:WO_Id/:WOTaskId"
        component={externalForm03}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm04/:WO_Id/:WOTaskId"
        component={externalForm04}
      />
      <Route
        path="/CASA_IDEAS/externalForm05/:CUSTOM_PARAM/:WO_Id/:WOTaskId"
        component={externalForm04}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm02/:WO_Id/:WOTaskId/:token"
        component={externalForm02}
      />

      <Route
        exact
        path="/CASA_IDEAS/externalForm02"
        component={externalForm02W2}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm03"
        component={externalForm03W2}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm04"
        component={externalForm04W2}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm06"
        component={externalForm06W2}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm07"
        component={externalForm07W2}
      />

      <Route
        exact
        path="/CASA_IDEAS/regalables/externalForm08"
        component={externalForm08W2}
      />

      <Route
        exact
        path="/CASA_IDEAS/regalables/externalForm10"
        component={externalForm10W2}
      />

      <Route
        path="/CASA_IDEAS/regalables/reportArqueo3"
        component={reportArqueo3W2}
      />

      <Route path="/CASA_IDEAS/reportArqueo2" component={reportArqueo2W2} />

      {/*version 2 para incluir facturacion xfloww*/}

      <Route
        exact
        path="/CASA_IDEAS_V2/externalForm02"
        component={externalForm02W3}
      />
      <Route
        exact
        path="/CASA_IDEAS_V2/externalForm03"
        component={externalForm03W3}
      />
      <Route
        exact
        path="/CASA_IDEAS_V2/externalForm04"
        component={externalForm04W3}
      />
      <Route
        exact
        path="/CASA_IDEAS_V2/externalForm06"
        component={externalForm06W3}
      />
      <Route
        exact
        path="/CASA_IDEAS_V2/externalForm07"
        component={externalForm07W3}
      />

      <Route
        exact
        path="/CASA_IDEAS_V2/regalables/externalForm08"
        component={externalForm08W3}
      />

      <Route
        exact
        path="/CASA_IDEAS_V2/regalables/externalForm10"
        component={externalForm10W3}
      />

      <Route
        path="/CASA_IDEAS_V2/regalables/reportArqueo3"
        component={reportArqueo3W3}
      />

      <Route path="/CASA_IDEAS_V2/reportArqueo4" component={reportArqueo4W3} />

      <Route path="/CASA_IDEAS_V2/reportArqueo2" component={reportArqueo2W3} />

      {/****menues especiales****/}
      <Route
        path="/CASA_IDEAS/externalManageSKU/:token"
        component={externalManageSKU}
      />
      <Route
        path="/CASA_IDEAS/externalUpdateSKU/:token"
        component={externalUpdateSKU}
      />

      <Route path="/CASA_IDEAS/reportSAP01/:token" component={reportSAP01} />
      <Route path="/CASA_IDEAS/reportSAP02/:token" component={reportSAP02} />
      <Route
        path="/CASA_IDEAS/reportArqueo1/:token"
        component={reportArqueo1}
      />
      <Route
        path="/CASA_IDEAS/reportArqueo2/:token"
        component={reportArqueo2}
      />
      <Route
        path="/CASA_IDEAS/reportArqueo3/:WO_Id/:WOTaskId/:token/:org"
        component={reportArqueo3}
      />
      <Route
        path="/CASA_IDEAS/reportBloqueos/:token"
        component={reportBloqueos}
      />

      <Route path="/CASA_IDEAS/resetFlujo/:token" component={resetFlowForm} />

      {/****formulario CI v2****/}
      <Route
        exact
        path="/CASA_IDEAS/externalForm01/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm01}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm03/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm03}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm04/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm04}
      />
      <Route
        path="/CASA_IDEAS/externalForm05/:CUSTOM_PARAM/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm04}
      />
      <Route
        exact
        path="/CASA_IDEAS/externalForm02/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm02}
      />

      {/****plataforma de pagos****/}
      <Route
        path="/payment_form/:token/:organization"
        exact
        component={cform01}
      />

      {/****export orders****/}
      <Route path="/CASA_IDEAS/exportorders/:token" exact component={cform02} />
      <Route
        path="/CASA_IDEAS/exportordersEvent/:token"
        exact
        component={cform03}
      />

      <Route path="/CASA_IDEAS/exportorders" exact component={cform02W2} />

      <Route path="/CASA_IDEAS/exportordersEvent" exact component={cform03W2} />
      <Route path="/CASA_IDEAS/exportordersHist" exact component={cform04W2} />

      <Route path="/CASA_IDEAS/exportCuadratura" exact component={cform05W2} />

      {/****formulario KETAL****/}
      <Route
        exact
        path="/KETAL_USER/externalForm01/:WO_Id/:WOTaskId"
        component={externalForm01K}
      />
      <Route
        exact
        path="/KETAL_USER/externalForm02/:WO_Id/:WOTaskId"
        component={externalForm02K}
      />
      <Route
        exact
        path="/KETAL_USER/externalForm04/:WO_Id/:WOTaskId"
        component={externalForm04K}
      />

      {/****formulario KETAL v2****/}
      <Route
        exact
        path="/KETAL_USER/externalForm01/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm01K}
      />
      <Route
        exact
        path="/KETAL_USER/externalForm02/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm02K}
      />
      <Route
        exact
        path="/KETAL_USER/externalForm04/:WO_Id/:WOTaskId/:token/:org"
        component={externalForm04K}
      />
    </div>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));
