import React from "react";
import {
  MDBSelect,
  MDBDatePicker,
  MDBAlert,
  MDBTableHead,
  MDBTable,
  ToastContainer,
  MDBTableBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";

import { API_CONFIG } from "../../Config/config-env.js";
import Pdf from "react-to-pdf";
import axios from "axios";
const ref = React.createRef();

class reportArqueo2 extends React.Component {
  state = {
    optionsT: [
      { value: 47, checked: true, text: "Envio a Domicilio" },
      { value: 85, text: "Recojo en Tienda" },
    ],
    options: [],
    userNameXF: "",
    userType: 47,
    SKU: "",
    rows1: [],
    noData: false,
    rows2: [],
    skuExists: "NO ENCONTRADO",
    skuState: "SIN INFORMACION",
    showResults: false,
    data: [],
    to_date: new Date(),
    from_date: new Date(),
    fecha: "",
    dotacion: 500,
    compinfo: {},

    fact_fin: 110,
    fact_pend: 0,
    fact_tot: 110,
    fact_nulas: 0,
    medios_efec: 110,
    medios_qr: 0,
    medios_atc: 0,
    fact_dely: 0,
    fact_todotix: 0,
    fact_iisa: 0,
    fact_total: 110,
    total_tarj_atc: 0,
    conv_qr_red_enlace: 0,
  };

  componentDidMount = () => {
    this.LoadSections(47);

    let f = new Date();
    let fecha =
      (f.getDate() < 10 ? "0" + f.getDate() : f.getDate()) +
      "/" +
      (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
      "/" +
      (f.getFullYear() + "");

    var hora = f.getHours();
    var minuto = f.getMinutes() < 10 ? "0" + f.getMinutes() : f.getMinutes();
    var segundo = f.getSeconds() < 10 ? "0" + f.getSeconds() : f.getSeconds();

    if (hora > 12) {
      hora = hora - 12 + ":" + minuto + " PM";
    } else if (hora === 0) {
      hora = "12" + ":" + minuto + ":" + segundo + "AM";
    } else if (hora === 12) {
      hora = "12" + ":" + minuto + ":" + segundo + "PM";
    } else {
      hora = hora + ":" + minuto + ":" + segundo + "AM";
    }

    this.setState({ fecha: fecha + " " + hora });
  };

  generateReport = async () => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    this.setState({ isExecuting: true, showResults: false });

    let url = API_CONFIG.xflowurl + "/components/get-component-report";

    var f = new Date(this.state.from_date);
    var fecha =
      (f.getDate() < 10 ? "0" + f.getDate() : f.getDate()) +
      "/" +
      (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
      "/" +
      (f.getFullYear() + "");
    var from_date = new Date(
      f.getFullYear(),
      f.getMonth(),
      f.getDate(),
      0,
      0,
      0
    );

    let datos = {
      components: this.state.userNameXF,
      from: from_date,
      usuario: this.state.userNameXF,
    };

    let components = this.state.compinfo.data;

    for (let i in components) {
      let component = components[i];
      let header = [];
      for (let j in component.values.options) {
        let obj = component.values.options[j];
        header.push(obj);
      }
      component["header"] = header;
    }
    // console.log("-+--------");
    // console.log(components);

    let filterDefinition = [
      {
        name: "usuario",
        type: "text",
        value: this.state.userNameXF,
      },
      {
        name: "from",
        type: "date",
        value: from_date,
      },
    ];
    /*
    if (this.state.userNameXF != "0") {
      console.log("hhay user");
      filterDefinition.push({
        name: "USERNAME",
        type: "input",
        value: this.state.userNameXF === "0" ? null : this.state.userNameXF,
      });
    }*/

    datos = {
      components: components,
      filterDefinition: filterDefinition,
      filters: {
        from: from_date,
        usuario: this.state.userNameXF,
      },
    };

    //console.log(datos);
    await axios({
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
      data: datos,
    })
      .then((response) => response.data)
      .then((data) => {
        console.log(data.results[1]);
        if (data.status) {
          this.setState({
            ...this.state,
            showResults: true,
            noData: false,
            rows1: data.results[0],
            rows2: data.results[1],
            rows3: data.results[2],
            username: data.username,
          });
          this.setState({ isExecuting: false });
        } else {
          this.setState({
            isExecuting: false,
            noData: true,
            showResults: false,
            data: [],
          });
        }
      });
  };

  LoadSections = async (userType) => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    this.setState({ isExecuting: true });

    await axios({
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/decrypt-token",
      //url : "http://localhost:4042/api/v1"+"/engine-users/decrypt-token",
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("data", data);
        if (data.status) {
          this.setState({
            ...this.state,
            username: data.token.USERNAME,
            organization: data.token.ORGANIZATIONCODE,
          });
        }
      });

    await axios({
      url: API_CONFIG.xflowurl + "/components/get-single-by-code",
      data: { component: "dashboards", code: "CIERRE_USUARIO" },
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        let compinfo = data.data.DATA;
        console.log("compinfo", compinfo);
        this.setState({ compinfo: compinfo });
      });

    let url = API_CONFIG.xflowurl + "/users/get-all";

    await axios({
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.result.length > 0) {
          var objSections = [];

          objSections.push({
            text: "TODOS",
            value: "0",
            checked: true,
          });

          for (var key in data.result) {
            let dataObject = {
              value: data.result[key].USERNAME,
              text: data.result[key].USERNAME,
            };
            objSections.push(dataObject);
          }

          this.setState({ options: objSections });

          this.setState({ isExecuting: false });
        } else {
          this.setState({ isExecuting: false });
        }
      });
  };

  selectHandlerT = (event) => {
    if (event[0]) {
      this.setState({ ...this.state, userType: event[0], userNameXF: "" });
      this.LoadSections(event[0]);
    }
  };

  selectHandler = (event) => {
    this.setState({ ...this.state, userNameXF: event[0] });
  };

  changeHandler = (event) => {
    //console.log(event.target.value);
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  getPickerValueTo = (value) => {
    this.setState({ to_date: value });
  };
  getPickerValueFrom = (value) => {
    this.setState({ from_date: value });
  };

  render() {
    return (
      <MDBContainer
        fluid
        className="justify-content-left text-left "
        style={{ marginLeft: "0" }}
      >
        <Loader idLoading={this.state.isExecuting} />

        {/*
        <MDBRow>
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBSelect
              className="is-invalid"
              key="store"
              id="store"
              color="primary"
              options={this.state.optionsT}
              getValue={this.selectHandlerT}
              selected="Elija el tipo de Envio"
              label="Tipo de Envio"
            />
          </MDBCol>
        </MDBRow>
        */}

        <MDBRow>
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBSelect
              className="is-invalid"
              key="store"
              id="store"
              color="primary"
              options={this.state.options}
              getValue={this.selectHandler}
              selected="Elija un Usuario"
              label="Usuarios"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBDatePicker
              key="from"
              name="from"
              id="from"
              format="DD/MM/YYYY"
              value={this.state.from_date}
              cancelLabel="Cancelar"
              getValue={this.getPickerValueFrom}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="dotacion"
              key="dotacion"
              name="dotacion"
              value={this.state.dotacion}
              onChange={this.changeHandler}
              size="sm"
              label="Dotación"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="cuad_total"
              key="cuad_total"
              name="cuad_total"
              value={this.state.cuad_total}
              onChange={this.changeHandler}
              size="sm"
              label="Total Efectivo"
            />
          </MDBCol>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="cuad_ret_dot"
              key="cuad_ret_dot"
              name="cuad_ret_dot"
              value={this.state.cuad_ret_dot}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro dotación"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="cuad_ret_efec"
              key="cuad_ret_efec"
              name="cuad_ret_efec"
              value={this.state.cuad_ret_efec}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro efectivo"
            />
          </MDBCol>

          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="cuad_ret_usd"
              key="cuad_ret_usd"
              name="cuad_ret_usd"
              value={this.state.cuad_ret_usd}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro USD."
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="retiro_tarj_atc"
              key="retiro_tarj_atc"
              name="retiro_tarj_atc"
              value={this.state.retiro_tarj_atc}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro tarj. ATC"
            />
          </MDBCol>

          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="conv_ret_qr_red_enlace"
              key="conv_ret_qr_red_enlace"
              name="conv_ret_qr_red_enlace"
              value={this.state.conv_ret_qr_red_enlace}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro QR RED ENLACE"
            />
          </MDBCol>
        </MDBRow>

        {/**
        <MDBRow>
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBDatePicker
              key="to"
              name="to"
              id="to"
              format="DD/MM/YYYY"
              value={this.state.to_date}
              cancelLabel="Cancelar"
              getValue={this.getPickerValueTo}
            />
          </MDBCol>
        </MDBRow>
 */}
        <MDBRow>
          <MDBCol
            sm="12"
            md="9"
            lg="4"
            className="text-right justify-content-right"
          >
            <MDBBtn
              href="#reportResult"
              key="find"
              onClick={() => {
                this.generateReport();
              }}
              size="sm"
              color="success"
              className="text-capitalize"
            >
              Generar Reporte
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        {this.state.noData ? (
          <>
            {" "}
            <br />
            <MDBAlert color="warning" dismiss>
              No se encontraron datos para el periodo seleccionado
            </MDBAlert>
          </>
        ) : (
          ""
        )}
        {this.state.showResults ? (
          <>
            <br />
            <hr />
            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="text-right justify-content-right"
              >
                <div
                  style={{
                    width: "650px",
                  }}
                  className="justify-content-right text-right"
                >
                  <Pdf targetRef={ref} filename="ReporteArqueo.pdf">
                    {({ toPdf }) => (
                      <MDBBtn
                        key="find"
                        size="sm"
                        color="success"
                        onClick={toPdf}
                      >
                        Exportar a Pdf
                      </MDBBtn>
                    )}
                  </Pdf>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="text-left justify-content-left"
              >
                <div ref={ref} className="pt-5 pl-5">
                  <MDBTable
                    id="table1"
                    small
                    responsive
                    style={{
                      width: "680px",
                    }}
                    className="tableBlue"
                    bordered
                  >
                    <MDBTableHead>
                      <tr>
                        <th className="text-center" colSpan="4">
                          <h5>
                            <strong> COMPROBANTE CIERRE DE USUARIO</strong>{" "}
                          </h5>
                        </th>
                      </tr>
                      
                    </MDBTableHead>
                    <MDBTableBody>
                      <tr>
                        <td> Fecha</td>
                        <td> {this.state.fecha}</td>
                        <td></td>
                        <td> </td>
                      </tr>

                      <tr>
                        <td> Usuario</td>
                        <td colSpan="3">{this.state.userNameXF}</td>
                      </tr>
                      <tr>
                        <td> Dotación</td>

                        <td colSpan="3"> {this.state.dotacion} </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan="4"> <b>COMPROBANTES EMITIDOS - VENTAS </b></td>
                      </tr>
                      <tr>
                        <td> FACTURAS - FINALIZADAS </td>
                        <td> {this.state.fact_fin}</td>
                        <td colSpan="2"> 1</td>

                      </tr>
                      <tr>
                        <td> FACTURAS - PENDIENTES DE COBRO </td>
                        <td  > {this.state.fact_pend}</td>
                        <td colSpan="2"> 0</td>

                      </tr>
                      <tr>
                        <td> TOTAL </td>
                        <td > {this.state.fact_tot}</td>
                        <td colSpan="2"> 1</td>
                      </tr>

                      <tr>
                        <td colSpan="4">
                          {" "}
                         <b> COMPROBANTES EMITIDOS - VENTAS NULAS</b>
                        </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> FACTURAS </td>
                        <td > {this.state.fact_nulas}</td>
                        <td colSpan="2"> 0</td>
                      </tr>

                      <tr>
                        <td colSpan="4"><b> MEDIOS DE PAGO - INGRESOS </b></td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> EFECTIVO </td>
                        <td > {this.state.medios_efec}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> QR RED ENLACE</td>
                        <td > {this.state.medios_qr}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> TARJ. ATC</td>
                        <td > {this.state.medios_atc}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> CONTRAENTREGA - DELIVERY</td>
                        <td  > {this.state.fact_dely}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> TODOTIX </td>
                        <td  > {this.state.fact_todotix}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> LINEA DE CREDITO IISA</td>
                        <td  > {this.state.fact_iisa}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> TOTAL </td>
                        <td  > {this.state.fact_total}</td>
                        <td colSpan="2"> 0</td>
                      </tr>

                      <tr>
                        <td colSpan="4"><b> CUADRATURA</b></td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> TOTAL EFECTIVO </td>
                        <td > {this.state.cuad_total}</td>
                        <td colSpan="2"> 1</td>
                      </tr>
                      <tr>
                        <td> RETIRO DOTACION</td>
                        <td > {this.state.cuad_ret_dot}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> RETIRO EFECTIVO</td>
                        <td > {this.state.cuad_ret_efec}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td>RETIRO USD.</td>
                        <td> {this.state.cuad_ret_usd}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> RESULTADO EFECTIVO </td>
                        <td> {this.state.cuad_res}</td>
                        <td colSpan="2"> 0</td>
                      </tr>

                      <tr>
                        <td colSpan="4"> </td>
                      </tr>

                      <tr>
                        <td> TOTAL TARJ. ATC</td>
                        <td > {this.state.total_tarj_atc}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> RETIRO TARJ. ATC </td>
                        <td > {this.state.retiro_tarj_atc}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> RESULTADO TARJ ATC/LINKSER</td>
                        <td colSpan="3">
                          {" "}
                          {this.state.total_tarj_atc -
                            this.state.retiro_tarj_atc}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="4"><b> CONVENIOS </b></td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> QR RED ENLACE </td>
                        <td > {this.state.conv_qr_red_enlace}</td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> RETIRO QR RED ENLACE</td>
                        <td >
                          {" "}
                          {this.state.conv_ret_qr_red_enlace}
                        </td>
                        <td colSpan="2"> 0</td>
                      </tr>
                      <tr>
                        <td> RESULTADO</td>
                        <td colSpan="3">
                          {" "}
                          {this.state.conv_qr_red_enlace -
                            this.state.conv_ret_qr_red_enlace}
                        </td>
                      </tr>

                      <tr>
                        <td> SOBRANTE</td>
                        <td colSpan="3"> {this.state.sobrante}</td>
                      </tr>
                      <tr>
                        <td> FALTANTE</td>
                        <td colSpan="3"> {this.state.faltante}</td>
                      </tr>

                      <tr>
                        <td> FACTURAS - PENDIENTES DE COBRO</td>
                        <td colSpan="3"> {this.state.fact_pend}</td>
                      </tr>

                      <tr>
                        <td>OBSERVACIONES</td>
                        <td colSpan="3">{this.state.observaciones}</td>
                      </tr>

                      <tr style={{ marginTop: 10 }}>
                        <th className="text-center" colSpan="4">
                          <h6>
                            <strong> Firma Usuario</strong>{" "}
                          </h6>
                        </th>
                      </tr>

                      <tr style={{ marginTop: 10 }}>
                        <th className="text-center" colSpan="4">
                          <h6>
                            <strong> Firma Supervisor</strong>{" "}
                          </h6>
                        </th>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </MDBCol>
            </MDBRow>
          </>
        ) : (
          ""
        )}

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={false}
          position={"bottom-right"}
          autoClose={3000}
        />
      </MDBContainer>
    );
  }
}
export default reportArqueo2;
